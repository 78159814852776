import { postHostedBusinessOnboarding } from '@onefootprint/axios';
import type { PostHostedBusinessOnboardingData } from '@onefootprint/request-types';
import { useMutation } from '@tanstack/react-query';

const startOnboarding = async (authToken: string, onboardingData: PostHostedBusinessOnboardingData) => {
  const { data } = await postHostedBusinessOnboarding({
    ...onboardingData,
    headers: { 'X-Fp-Authorization': authToken },
    throwOnError: true,
  });
  return data;
};

const useStartBusinessOnboarding = (authToken: string, onError: (error: unknown) => void) => {
  return useMutation({
    mutationFn: (onboardingData: PostHostedBusinessOnboardingData) => {
      return startOnboarding(authToken, onboardingData);
    },
    onError,
  });
};

export default useStartBusinessOnboarding;

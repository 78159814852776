import { getLogger } from '@/idv/utils';
import { load } from '@fingerprintjs/fingerprintjs-pro';
import { postHostedOnboardingFp } from '@onefootprint/axios';
import { IS_E2E, IS_TEST } from '@onefootprint/dev-tools';
import type { FingerprintVisitPath } from '@onefootprint/request-types';
import { useEffect } from 'react';

const { logError, logWarn } = getLogger({ location: 'use-fingerprint' });

export type FingerprintData = {
  visitorId: string;
  requestId: string;
};

const apiKey = process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY;
const path = process.env.NEXT_PUBLIC_FINGERPRINT_PATH;
const isDisabled = IS_TEST || IS_E2E;

const fetchFingerprintData = async (apiKey: string): Promise<FingerprintData> => {
  const fp = await load({ apiKey });
  const result = await fp.get();
  return {
    visitorId: result.visitorId,
    requestId: result.requestId,
  };
};

const loadAndSubmit = async (options: { authToken: string }) => {
  try {
    if (!apiKey || !getPath(path)) {
      logWarn('No fingerprint API key or path found');
      return;
    }
    const data = await fetchFingerprintData(apiKey);
    await postHostedOnboardingFp({
      body: {
        visitorId: data.visitorId,
        requestId: data.requestId,
        path,
      },
      headers: {
        'X-Fp-Authorization': options.authToken,
      },
    });
  } catch (error) {
    logError('Error fetching fingerprint data:', error);
  }
};

const getPath = (path: string | undefined): path is FingerprintVisitPath => {
  return path === 'onboard_start' || path === 'handoff';
};

const useFingerprint = ({ authToken }: { authToken?: string }) => {
  useEffect(() => {
    if (isDisabled) {
      console.log('Skipping fingerprint initialization as we are in test or e2e environment');
      return;
    }
    if (authToken) {
      loadAndSubmit({ authToken });
    }
  }, [authToken]);
};

export default useFingerprint;

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type React from 'react';

type TriggerProps = {
  children: React.ReactElement;
  asChild?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
};

const Trigger = ({ children, asChild, ariaLabel }: TriggerProps) => {
  return (
    <TooltipPrimitive.Trigger aria-label={ariaLabel} asChild={asChild}>
      {children}
    </TooltipPrimitive.Trigger>
  );
};

export default Trigger;

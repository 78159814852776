import { IcoCopy16, IcoCopy24 } from '@onefootprint/icons';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { IconButton } from '../index';

export type CopyButtonProps = {
  ariaLabel?: string;
  disabled?: boolean;
  children?: string | React.ReactNode;
  contentToCopy: string;
  size?: 'default' | 'large' | 'compact';
  tooltip?: {
    position?: TooltipPrimitive.TooltipContentProps['side'];
    alignment?: TooltipPrimitive.TooltipContentProps['align'];
    text?: string;
    textConfirmation?: string;
  };
};

const HIDE_TIMEOUT = 600;

let confirmationTimeout: null | ReturnType<typeof setTimeout> = null;

const CopyButton = ({
  ariaLabel,
  size = 'default',
  tooltip = {
    position: 'right',
    alignment: 'center',
    text: 'Copy to clipboard',
    textConfirmation: 'Copied!',
  },
  children,
  disabled,
  contentToCopy,
}: CopyButtonProps) => {
  const { t } = useTranslation('ui');
  const [shouldShowConfirmation, setShowConfirmation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const CopyIcon = size === 'large' ? IcoCopy24 : IcoCopy16;

  useEffect(
    () => () => {
      clearTooltipTimeout();
    },
    [],
  );

  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setShowConfirmation(true);
    setIsOpen(true);
    scheduleToHideConfirmation();
    navigator.clipboard.writeText(contentToCopy);
  };

  const clearTooltipTimeout = () => {
    if (confirmationTimeout) {
      clearTimeout(confirmationTimeout);
      confirmationTimeout = null;
    }
  };

  const scheduleToHideConfirmation = () => {
    confirmationTimeout = setTimeout(() => {
      setShowConfirmation(false);
      setIsOpen(false);
    }, HIDE_TIMEOUT);
  };

  const handleText = () => {
    const tooltipText = tooltip.text ?? (t('components.copy-button.tooltip-text-default') as string);
    const tooltipTextConfirmation =
      tooltip.textConfirmation ?? (t('components.copy-button.tooltip-text-confirmation-default') as string);
    if (isMobile) {
      return tooltipTextConfirmation;
    }
    return shouldShowConfirmation ? tooltipTextConfirmation : tooltipText;
  };

  return (
    <TooltipPrimitive.Provider delayDuration={0}>
      <TooltipPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
        <TooltipPrimitive.Trigger asChild>
          {children ? (
            <button
              className="max-w-full cursor-pointer disabled:pointer-events-none w-fit h-fit"
              aria-label={ariaLabel ?? (t('components.copy-button.aria-label-default') as string)}
              type="button"
              disabled={disabled}
              onClick={handleClick}
            >
              {children}
            </button>
          ) : (
            <IconButton
              aria-label={ariaLabel ?? (t('components.copy-button.aria-label-default') as string)}
              disabled={disabled}
              onClick={handleClick}
              size={size}
              icon={CopyIcon}
            />
          )}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side={tooltip.position}
            align={tooltip.alignment}
            sideOffset={4}
            className="min-w-fit w-fit max-w-[300px] px-2 py-1 text-caption-3 text-quinary text-left rounded-sm bg-tertiary shadow-md z-tooltip"
          >
            {handleText()}
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default CopyButton;

import * as RadixToggle from '@radix-ui/react-toggle';
import { cva, cx } from 'class-variance-authority';
import { forwardRef, useId } from 'react';

export type ToggleProps = {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  hint?: string;
  labelPlacement?: 'left' | 'right';
  onChange?: (checked: boolean) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  size?: 'default' | 'compact';
  className?: string;
};

const toggleVariants = cva('gap-3 items-start group inline-flex', {
  variants: {
    labelPlacement: {
      right: 'flex-row-reverse',
      left: 'flex-row',
    },
    hasHint: {
      false: 'items-center',
      true: '',
    },
    disabled: {
      true: '',
      false: 'cursor-pointer',
    },
    size: {
      compact: '',
      default: '',
    },
  },
});

const switchVariants = cva(
  [
    'relative rounded-full border-2 border-solid transition-all duration-200 ease-in-out overflow-hidden cursor-pointer',
    'group-focus-visible:ring-2 ring-offset-2',
  ],
  {
    variants: {
      size: {
        compact: 'w-8 h-5',
        default: 'w-9 h-6',
      },
      disabled: {
        true: 'pointer-events-none opacity-40',
        false: '',
      },
      pressed: {
        true: 'bg-accent border-transparent hover:opacity-90',
        false: 'border-primary bg-secondary hover:bg-senary',
      },
    },
    defaultVariants: {
      size: 'default',
      pressed: false,
    },
  },
);

const circleVariants = cva(['absolute transition-transform duration-200 left-0.5 top-1/2 -translate-y-1/2'], {
  variants: {
    size: {
      compact: 'w-3 h-3',
      default: 'w-4 h-4',
    },
    disabled: {
      true: 'opacity-100',
      false: '',
    },
    pressed: {
      true: 'translate-x-[13px] text-quinary',
      false: 'text-tertiary',
    },
  },
});

const labelVariants = cva('text-left text-primary cursor-pointer', {
  variants: {
    size: {
      compact: 'text-body-3',
      default: 'text-body-2',
    },
  },
});

const hintVariants = cva('text-left text-tertiary', {
  variants: {
    size: {
      compact: 'text-body-3',
      default: 'text-body-2',
    },
  },
});

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  (
    {
      label,
      hint,
      labelPlacement = 'right',
      checked,
      defaultChecked,
      disabled,
      onChange,
      onBlur,
      size = 'default',
      className,
    }: ToggleProps,
    ref,
  ) => {
    const id = useId();

    return (
      <RadixToggle.Root
        role="switch"
        disabled={disabled}
        defaultPressed={defaultChecked}
        pressed={checked}
        onPressedChange={onChange}
        onBlur={onBlur}
        className={toggleVariants({
          labelPlacement,
          hasHint: !!hint,
          disabled,
          className,
        })}
        style={{ WebkitTapHighlightColor: 'transparent' }}
        ref={ref}
      >
        {(label || hint) && (
          <div className="flex flex-col flex-1">
            {label && (
              <label htmlFor={id} className={cx(labelVariants({ size }))}>
                {label}
              </label>
            )}
            {hint && <div className={cx(hintVariants({ size }))}>{hint}</div>}
          </div>
        )}

        <span
          className={switchVariants({
            size,
            disabled,
            pressed: checked ?? false,
          })}
        >
          <svg
            className={circleVariants({
              size,
              disabled,
              pressed: checked ?? false,
            })}
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" fill="currentColor" />
          </svg>
        </span>
      </RadixToggle.Root>
    );
  },
);

export default Toggle;

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type React from 'react';

type ContentProps = {
  children: React.ReactNode;
  position?: TooltipPrimitive.TooltipContentProps['side'];
  alignment?: TooltipPrimitive.TooltipContentProps['align'];
  sideOffset?: TooltipPrimitive.TooltipContentProps['sideOffset'];
  collisionBoundary?: TooltipPrimitive.TooltipContentProps['collisionBoundary'];
};

const Content = ({
  children,
  position = 'top',
  alignment = 'center',
  sideOffset = 8,
  collisionBoundary,
  ...props
}: ContentProps) => {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        side={position}
        align={alignment}
        sideOffset={sideOffset}
        collisionBoundary={collisionBoundary}
        className="max-w-[300px] px-2 py-1 text-caption-3 text-quinary text-left rounded bg-tertiary shadow-md z-50"
        {...props}
      >
        {children}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
};

export default Content;

import { cx } from 'class-variance-authority';
import { getIconForVariant } from './inline-alert.utils';

export type InlineAlertVariant = 'error' | 'warning' | 'info' | 'neutral';

export type InlineAlertProps = {
  variant: InlineAlertVariant;
  children: React.ReactNode;
  className?: string;
  cta?: {
    label: string;
    onClick: () => void;
  };
};

const InlineAlert = ({ cta, children, variant = 'info', className, ...props }: InlineAlertProps) => {
  const IconComponent = getIconForVariant(variant);

  return (
    <div
      role="alert"
      className={cx(
        'flex flex-row align-top justify-start w-full p-2 gap-2 rounded',
        {
          'bg-error': variant === 'error',
          'bg-warning': variant === 'warning',
          'bg-info': variant === 'info',
          'bg-primary border border-tertiary border-solid': variant === 'neutral',
        },
        className,
      )}
      {...props}
    >
      <div className="flex items-center justify-center flex-none h-5">
        <IconComponent color={variant === 'neutral' ? 'primary' : variant} />
      </div>
      <span
        className={cx('text-body-3 align-middle', {
          'text-error': variant === 'error',
          'text-warning': variant === 'warning',
          'text-info': variant === 'info',
          'text-primary': variant === 'neutral',
        })}
      >
        {children}
        {cta && (
          <button
            onClick={cta.onClick}
            className={cx('ml-1 underline cursor-pointer hover:opacity-70 active:opacity-85', {
              'text-current': variant !== 'neutral',
              'text-accent': variant === 'neutral',
            })}
            type="button"
          >
            {cta.label}
          </button>
        )}
      </span>
    </div>
  );
};

export default InlineAlert;

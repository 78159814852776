import useFingerprint from '@/idv/hooks/use-fingerprint';
import OnboardingMachineProvider from './components/machine-provider';
import Router from './pages/router';
import type { OnboardingProps } from './types';

const Onboarding = ({ onDone, ...args }: OnboardingProps) => {
  const {
    idvContext: { authToken },
  } = args;
  useFingerprint({ authToken });

  return (
    <OnboardingMachineProvider args={args}>
      <Router onDone={onDone} />
    </OnboardingMachineProvider>
  );
};

export default Onboarding;

import { HeaderTitle, NavigationHeader } from '@/idv/components';
import { getLogger, trackAction } from '@/idv/utils';
import { IcoCamera40, IcoImages24 } from '@onefootprint/icons';
import { Button, media, useToast } from '@onefootprint/ui';
import type { TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import { FlexColumnMotionContainer } from '../../id-doc/pages/mobile-fallback-upload/mobile-fallback-upload';
import { getMediaStream } from '../camera/hooks/use-user-media';

type T = TFunction<'idv', 'document-flow'>;
type CameraAccessRequestProps = {
  onClose: () => void;
  onError: (error: unknown) => void;
  onSuccess: () => void;
};

const underlineStyle = { textDecoration: 'underline' };
const { logError } = getLogger({ location: 'camera-access-request' });

const showFeedbackToast = (t: T, toast: (str: string) => string, err: unknown): unknown => {
  if (!(err instanceof Error)) {
    logError('Unknown MediaStream error', err);
    return err;
  }

  if (err instanceof TypeError) {
    toast(t('components.media-errors.undefined-navigator'));
    trackAction('id-doc:camera-error', { cameraError: 'undefined-navigator' });
  } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
    toast(t('components.media-errors.not-found')); // required track is missing
    trackAction('id-doc:camera-error', { cameraError: 'not-found' });
  } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
    toast(t('components.media-errors.already-in-use')); // webcam or mic are already in use
    trackAction('id-doc:camera-error', { cameraError: 'already-in-use' });
  } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
    toast(t('components.media-errors.constraint')); // constraints can not be satisfied by avb. devices
    trackAction('id-doc:camera-error', { cameraError: 'constraint' });
  } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
    trackAction('id-doc:camera-error', { cameraError: 'permission-denied' });
  } else {
    toast(t('components.media-errors.other-error')); // other errors
    trackAction('id-doc:camera-error', { cameraError: 'other-error' });
  }

  logError(`MediaStream error ${err.name}`, err);
  return err;
};

const CameraAccessRequest = ({ onClose, onError, onSuccess }: CameraAccessRequestProps) => {
  const { t } = useTranslation('idv', { keyPrefix: 'document-flow' });
  const toast = useToast();
  const errorToast = (description: string) => toast.show({ description, title: 'Uh-oh!', variant: 'error' });

  const handleAllowAccess = async () => {
    try {
      const stream = await getMediaStream({ video: true });

      // Now that we have the stream, it means the user has granted access
      // So, let's stop the stream and call the onSuccess callback
      stream.getTracks().forEach(track => track.stop());
      onSuccess();
    } catch (err) {
      showFeedbackToast(t, errorToast, err);
      onError(err);
    }
  };

  return (
    <FlexColumnMotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <NavigationHeader leftButton={{ variant: 'back', onBack: onClose }} position="floating" />
      <HeaderTitle title={t('access-camera')} icon={IcoCamera40} display="flex" flexDirection="column" />
      <div className="flex gap-3">
        <IcoImages24 className="min-w-5" />
        <div>
          <p className="text-label-2">
            <Trans
              ns="idv"
              i18nKey="document-flow.how-you-will-use-this"
              components={{ underlined: <span style={underlineStyle} /> }}
            />
          </p>
          <p className="text-body-2 text-tertiary">{t('docs-and-or-selfie')}</p>
        </div>
      </div>
      <div className="flex gap-3">
        <IcoImages24 className="min-w-5" />
        <div>
          <p className="text-label-2">
            <Trans
              ns="idv"
              i18nKey="document-flow.how-we-will-use-this"
              components={{ underlined: <span style={underlineStyle} /> }}
            />
          </p>
          <p className="text-body-2 text-tertiary">{t('key-needed-for-verification')}</p>
          <p className="text-label-2">{t('no-camera-access')}</p>
        </div>
      </div>
      <Button fullWidth onClick={handleAllowAccess} testID="allow-access" size="large">
        {t('allow-access')}
      </Button>
      <GlobalFootPrintFooterModification />
    </FlexColumnMotionContainer>
  );
};

const GlobalFootPrintFooterModification = createGlobalStyle`
  ${media.lessThan('md')`
    #footprint-footer { display: none; }
    #idv-body-content-container > div { padding-bottom: 0; }
  `}
`;

export default CameraAccessRequest;

import { IcoForbid40 } from '@onefootprint/icons';
import { Text } from '@onefootprint/ui';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { getLogger } from '@/idv/utils';
import { useRequestError } from '@onefootprint/request';
import Link from 'next/link';
import { useEffect } from 'react';
import { NavigationHeader } from '../../../../components/layout';

type ErrorComponentProps = {
  error?: unknown;
};

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const { t } = useTranslation('idv', {
    keyPrefix: 'onboarding.components.error',
  });
  const { getErrorCode } = useRequestError();
  const { logError } = getLogger({ location: 'onboarding-components-error' });

  const getErrorMessage = () => {
    const errorCode = getErrorCode(error);
    if (errorCode === 'E130') {
      return (
        <Trans
          ns="idv"
          i18nKey="onboarding.components.error.description-authless-playbook-requires-backend"
          components={{
            onboardingSessionLink: (
              <Link
                href="https://docs.onefootprint.com/articles/integrate/onboarding-sessions#example-use-cases-guarantee-resolution-to-a-specific-user"
                target="_blank"
                className="text-label-3 text-accent hover:text-accent-hover"
              >
                {t('onboarding-session')}
              </Link>
            ),
            fpId: <code className="align-baseline">fp_id</code>,
            userExternalId: <code className="align-baseline">user_external_id</code>,
          }}
        />
      );
    }
    if (errorCode === 'E124') {
      return t('description-business-not-owned-by-user');
    }
    return t('description');
  };

  useEffect(() => {
    logError('ErrorComponent', { error });
  }, []);

  return (
    <Container>
      <NavigationHeader leftButton={{ variant: 'close', confirmClose: false }} />
      <TitleContainer>
        <IcoForbid40 color="error" />
        <Text variant="heading-3">{t('title')}</Text>
      </TitleContainer>
      <Text variant="body-2" textAlign="center">
        {getErrorMessage()}
      </Text>
    </Container>
  );
};

const TitleContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing[2]};
    justify-content: center;
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing[7]};
    justify-content: center;
    align-items: center;
    min-height: var(--loading-container-min-height);
  `}
`;

export default ErrorComponent;

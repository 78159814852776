import type { BootstrapData } from '@/idv/types';
import { isEmail, isPhoneNumber } from '@onefootprint/core';

const validatePhoneAndEmail = (bootstrapData?: BootstrapData) => {
  const email = bootstrapData?.['id.email']?.value;
  const phoneNumber = bootstrapData?.['id.phone_number']?.value;

  const validateField = (isValid: boolean, value: string) => {
    return isValid ? { value, isBootstrap: true } : undefined;
  };

  return {
    email: email ? validateField(isEmail(email), email) : undefined,
    phoneNumber: phoneNumber ? validateField(isPhoneNumber(phoneNumber), phoneNumber) : undefined,
  };
};

export default validatePhoneAndEmail;

import ErrorComponent from '@/idv/pages/onboarding/components/error';
import { postHostedIdentifySession } from '@onefootprint/axios';
import type { IdentifyScope } from '@onefootprint/request-types';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type Context, IdentifyVariant } from '../identify.types';
import Loading from './loading';

const variantToTokenScope: Record<IdentifyVariant, IdentifyScope> = {
  [IdentifyVariant.auth]: 'auth',
  [IdentifyVariant.updateLoginMethods]: 'auth',
  [IdentifyVariant.verify]: 'onboarding',
};

type InitProps = {
  context: Context;
  onDone: (identifyToken: string) => Promise<void>;
};

const Init = ({ context, onDone }: InitProps) => {
  const {
    state,
    initArgs,
    onError,
    initArgs: { bootstrapData },
  } = context;

  let vaultData = {};
  const hasAuthToken = !!initArgs.initialAuthToken;
  if (!hasAuthToken) {
    vaultData = {
      'id.email': state.email?.value,
      'id.phone_number': state.phoneNumber?.value,
    };
    if (bootstrapData) {
      vaultData = Object.fromEntries(Object.entries(bootstrapData).map(([k, { value }]) => [k, value]));
    }
  }

  const sessionInitMutation = useMutation({
    mutationFn: async () => {
      const { data } = await postHostedIdentifySession({
        body: {
          data: vaultData,
          scope: variantToTokenScope[initArgs.variant],
        },
        headers: {
          ...initArgs.obConfigAuth,
          'X-Fp-Authorization': initArgs.initialAuthToken,
          'X-Fp-Is-Bootstrap': state.email?.isBootstrap || state.phoneNumber?.isBootstrap,
          'X-Fp-Is-Components-Sdk': initArgs.isComponentsSdk,
          'X-Sandbox-Id': initArgs.isLive || hasAuthToken ? undefined : initArgs.sandboxId,
        },
        throwOnError: true,
      });

      await onDone(data.token);
    },
    onError: error => {
      onError('init', error);
    },
  });

  useEffect(() => {
    sessionInitMutation.mutate();
  }, []);

  if (sessionInitMutation.isError) {
    return <ErrorComponent error={sessionInitMutation.error} />;
  }

  return <Loading />;
};

export default Init;

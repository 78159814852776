import { CollectedKybDataOption, CollectedKybDataOptionToRequiredAttributes } from '@onefootprint/types';
import type { MachineContext } from '../state-machine/types';

const shouldRecollect = (ctx: MachineContext, cdos: CollectedKybDataOption[]) => {
  return ctx.kybRequirement.recollectAttributes.some(cdo => cdos.includes(cdo));
};

/** Given a list of CDOs, returns whether we are missing any underlying data for the CDOs. */
const isMissingAnyData = (ctx: MachineContext, cdos: CollectedKybDataOption[]): boolean => {
  const missingCdos = ctx.kybRequirement.missingAttributes.filter(cdo => cdos.includes(cdo));
  return missingCdos.flatMap(cdo => CollectedKybDataOptionToRequiredAttributes[cdo]).some(di => !ctx.data[di]);
};

export const shouldShowBasicDataScreen = (ctx: MachineContext): boolean => {
  const basicCdos = [
    CollectedKybDataOption.name,
    CollectedKybDataOption.tin,
    CollectedKybDataOption.corporationType,
    CollectedKybDataOption.phoneNumber,
    CollectedKybDataOption.website,
  ];
  return isMissingAnyData(ctx, basicCdos) || shouldRecollect(ctx, basicCdos);
};

export const shouldShowAddressDataScreen = (ctx: MachineContext): boolean => {
  const addressCdos = [CollectedKybDataOption.address];
  return isMissingAnyData(ctx, addressCdos) || shouldRecollect(ctx, addressCdos);
};

export const shouldShowManageBosScreen = (ctx: MachineContext): boolean => {
  const boCdos = [CollectedKybDataOption.kycedBeneficialOwners];
  const isMissingBos = ctx.kybRequirement.missingAttributes.some(cdo => boCdos.includes(cdo));
  return isMissingBos || shouldRecollect(ctx, boCdos);
};

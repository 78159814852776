import { OnboardingRequirementKind, getRequirement } from '@onefootprint/types';
import { UserChallengeActionKind } from '@onefootprint/types';
import { getRequirements } from '@onefootprint/types/src/api/onboarding-status';
import { useEffect } from 'react';

import { useLogStateMachine } from '@/idv/hooks';
import {
  CollectCustomData,
  CollectDocument,
  CollectKybData,
  CollectKycData,
  InvestorProfile,
  Liveness,
  Transfer,
} from '@/idv/plugins';
import CreateBusinessOnboarding from '@/idv/plugins/create-business-onboarding';
import { getLogger } from '@/idv/utils';
import type { OnboardingRequirementCollectCustomData } from '@onefootprint/request-types';
import ErrorComponent from '../../../../components/error';
import WaitForComponentsSdk from '../../components/wait-for-components-sdk';
import useOnboardingRequirementsMachine from '../../hooks/use-onboarding-requirements-machine';
import { getPreferUploadDoc } from '../../utils/state-machine/machine.utils';
import Authorize from '../authorize';
import CheckRequirements from '../check-requirements';
import Process from '../process';
import StartOnboarding from '../start-onboarding';

type RouterProps = { onDone: () => void };

const { logInfo } = getLogger({ location: 'onboarding-requirements-router' });

const Router = ({ onDone }: RouterProps) => {
  const [state, send] = useOnboardingRequirementsMachine();
  const {
    idvContext,
    onboardingContext: { config, idDocOutcome, overallOutcome },
    handledRequirements,
    requirements,
    isTransferFromDesktopToMobileDisabled,
    error,
  } = state.context;
  const isDone = state.matches('success');
  const createBusinessOnboarding = getRequirement(requirements, OnboardingRequirementKind.createBusinessOnboarding);
  const kyb = getRequirement(requirements, OnboardingRequirementKind.collectKybData);
  const kyc = getRequirement(requirements, OnboardingRequirementKind.collectKycData);
  const liveness = getRequirement(requirements, OnboardingRequirementKind.registerPasskey);
  const idDocReqs = getRequirements(requirements, OnboardingRequirementKind.document);
  const customDataRequirements = getRequirements(requirements, 'collect_custom_data');
  const nextCustomDataRequirement = customDataRequirements[0] as OnboardingRequirementCollectCustomData; // a little hacky casting, but will be better implemented when we move away from deprecated types
  useLogStateMachine('onboarding-requirements', state);

  useEffect(() => {
    if (isDone) {
      logInfo('Onboarding requirements flow is complete');
      onDone();
    }
  }, [isDone, onDone]);

  const handleRequirementCompleted = () => {
    send({ type: 'requirementCompleted' });
  };

  const handleError = (error: unknown) => {
    send({ type: 'error', payload: { error } });
  };

  if (state.matches('startOnboarding')) {
    return <StartOnboarding />;
  }
  if (state.matches('waitForComponentsSdk')) {
    return <WaitForComponentsSdk onDone={handleRequirementCompleted} />;
  }
  if (state.matches('checkRequirements')) {
    return <CheckRequirements />;
  }
  if (state.matches('createBusinessOnboarding') && createBusinessOnboarding) {
    return (
      <CreateBusinessOnboarding
        idvContext={idvContext}
        context={{
          requirement: createBusinessOnboarding,
          overallOutcome,
        }}
        onDone={handleRequirementCompleted}
        onError={handleError}
      />
    );
  }
  if (state.matches('kybData') && kyb) {
    return (
      <CollectKybData
        idvContext={idvContext}
        context={{
          kybRequirement: kyb,
          config,
        }}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('kycData') && kyc) {
    return (
      <CollectKycData
        idvContext={idvContext}
        context={{
          requirement: kyc,
          config,
        }}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('customData')) {
    return (
      <CollectCustomData
        collectCustomDataRequirement={nextCustomDataRequirement}
        idvContext={idvContext}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('investorProfile')) {
    return (
      <InvestorProfile
        idvContext={idvContext}
        context={{
          showTransition: handledRequirements.includes(OnboardingRequirementKind.collectKycData),
          investorRequirement: getRequirement(requirements, OnboardingRequirementKind.investorProfile),
        }}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('transfer')) {
    return (
      <Transfer
        idvContext={idvContext}
        context={{
          config,
          missingRequirements: {
            liveness,
            documents: idDocReqs,
          },
          idDocOutcome,
          isTransferFromDesktopToMobileDisabled,
        }}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('liveness')) {
    return (
      <Liveness
        actionKind={UserChallengeActionKind.addPrimary}
        idvContext={idvContext}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('idDoc') && idDocReqs.length) {
    return (
      <CollectDocument
        idvContext={idvContext}
        context={{
          // If we have a custom document with upload settings set as "prefer_upload", we should first request the user to upload the document before transferring
          requirement: getPreferUploadDoc(idDocReqs) || idDocReqs[0],
          sandboxOutcome: idDocOutcome,
          obConfigSupportedCountries: config.supportedCountries,
          config,
        }}
        onDone={handleRequirementCompleted}
      />
    );
  }
  if (state.matches('authorize')) {
    return <Authorize onDone={handleRequirementCompleted} />;
  }
  if (state.matches('process')) {
    return <Process onDone={handleRequirementCompleted} />;
  }
  if (state.matches('error')) {
    return <ErrorComponent error={error} />;
  }

  return null;
};

export default Router;

import { cx } from 'class-variance-authority';

export type DividerProps = {
  className?: string;
  variant?: 'primary' | 'secondary';
};

const Divider = ({ variant = 'primary', className }: DividerProps) => {
  return (
    <hr
      aria-orientation="horizontal"
      className={cx(
        'w-full border-0 border-b border-tertiary opacity-100',
        variant === 'primary' ? 'border-solid' : 'border-dashed',
        className,
      )}
    />
  );
};

export default Divider;

import { MachineProvider } from './components/machine-provider';
import Router from './pages/router';
import type { CollectKycDataProps } from './types';

const CollectKycData = ({ idvContext, context, onDone }: CollectKycDataProps) => {
  const { authToken, device } = idvContext;
  const { config, requirement } = context;

  const initContext = {
    authToken,
    device,
    config,
    requirement,
    data: {},
    initialData: {},
  };

  return (
    <MachineProvider initialContext={initContext}>
      <Router onDone={onDone} />
    </MachineProvider>
  );
};

export default CollectKycData;

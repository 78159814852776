import { useToast } from '@onefootprint/ui';
import imageCompression from 'browser-image-compression';
import partial from 'lodash/fp/partial';

import { getLogger } from '@/idv/utils';
import { useTranslation } from 'react-i18next';
import { useImgProcessorsContext } from '../../components/image-processors';
import { bytesToMegabytes, hasFileReaderSupport, isFileOrBlob, isString } from '../../utils/capture';
import compressImage, { COMPRESS_EXTRA_MAX_SIZE_MB } from './utils/compress-image';
import imageFileToStrippedBase64 from './utils/image-file-to-stripped-base64';
import resizeImage from './utils/resize-image';

type ProcessedImageFile = { file: File | Blob; extraCompressed: boolean };
type HandleError = (step: ImageProcessingStepError, error?: unknown) => void;
type ImageProcessors = ReturnType<typeof useImgProcessorsContext>;
enum ImageProcessingStepError {
  heic = 'heic',
  resize = 'resize',
  compress = 'compress',
  other = 'other',
  final = 'final',
}

export enum ImageFileOrUrlProcessingError {
  heicNotSupported = 'heicNotSupported',
  compressionFailed = 'compressionFailed',
  other = 'other',
}

export type ProcessingErrorResult = {
  error: ImageFileOrUrlProcessingError;
};

export function isProcessingResultError(
  result: ProcessedImageFile | ProcessingErrorResult,
): result is ProcessingErrorResult {
  return 'error' in result;
}

const isError = (x: unknown): x is Error => x instanceof Error;
const isHeicType = (x: unknown) => x === 'image/heic';

const { logError, logInfo, logWarn } = getLogger({
  location: 'use-process-image',
});

const stringify = (x: unknown): string => {
  try {
    return isString(x) ? x : JSON.stringify(x);
  } catch (e: unknown) {
    if (isError(e)) {
      logWarn(`Error during JSON.stringify: ${e?.message}`, e);
    }
    return '';
  }
};

const convertImageFileToStrippedBase64 = async (onError: HandleError, file: File): Promise<string | undefined> => {
  try {
    return await imageFileToStrippedBase64(file);
  } catch (error) {
    onError(ImageProcessingStepError.other, error);
    return undefined;
  }
};

const stepHeicConversion = async (
  onError: HandleError,
  imageProcessors: ImageProcessors | undefined,
  file: File,
): Promise<File | Error> => {
  try {
    if (imageProcessors?.heic && !imageProcessors.heicLoading) {
      const res = await imageProcessors.heic({
        blob: file,
        toType: 'image/jpeg',
      });
      const blob = Array.isArray(res) ? res : [res];
      return new File(blob, file.name, {
        type: 'image/jpeg',
        lastModified: file.lastModified,
      });
    }

    logWarn('HEIC image processor not available');
    return new Error('HEIC image processor not available');
  } catch (e: unknown) {
    onError(ImageProcessingStepError.heic, e);
    return isError(e) ? e : new Error('HEIC processing failed');
  }
};

const stepImageResize = async (onError: HandleError, file: File): Promise<File | Blob | Error> => {
  try {
    const resized = await resizeImage(file);
    return resized || new Error('resize processing failed');
  } catch (e) {
    onError(ImageProcessingStepError.resize, e);
    return isError(e) ? e : new Error('resize processing failed');
  }
};

const stepImageCompression = async (
  onError: HandleError,
  file: File | Blob,
  extraCompress: boolean = false,
): Promise<File | Error> => {
  try {
    const compressed = await compressImage(file, extraCompress);
    return compressed || new Error('compress processing failed');
  } catch (e) {
    onError(ImageProcessingStepError.compress, e);
    return isError(e) ? e : new Error('compress processing failed');
  }
};

/**
 * Processes a file by performing HEIC conversion, image resizing, and compression.
 *
 * @param {HandleError} onError - Function to handle errors.
 * @param {ImageProcessors | undefined} imageProcessors - Image processors configuration.
 * @param {File} file - The input file to be processed.
 * @param {boolean} [extraCompressFlag] - Optional flag for extra compression.
 * @returns {Promise<ProcessedImageFile | undefined>} A Promise that resolves to the processed file
 * or undefined if an error occurs during processing.
 *
 * @typedef {Object} ProcessedImageFile - Object containing the processed file and extra compression flag.
 * @property {File} file - The processed file.
 * @property {boolean} extraCompressed - Indicates if extra compression was applied.
 */
const runProcessFileScript = async (
  onError: HandleError,
  imageProcessors: ImageProcessors | undefined,
  file: File,
  extraCompressFlag?: boolean,
): Promise<ProcessedImageFile | ProcessingErrorResult> => {
  logInfo(`file original ${bytesToMegabytes(file.size)} MB ${file.type}`);

  if (file.type === 'application/pdf') {
    return { file, extraCompressed: false };
  }

  const heicOutput = isHeicType(file.type) ? await stepHeicConversion(onError, imageProcessors, file) : file;

  if (isError(heicOutput)) {
    logWarn('HEIC conversion failed: halting processing');
    return { error: ImageFileOrUrlProcessingError.heicNotSupported };
  }

  const resizeInput = isFileOrBlob(heicOutput) ? heicOutput : file;
  let resizeOutput;
  if (isHeicType(resizeInput.type)) {
    resizeOutput = resizeInput;
  } else {
    resizeOutput = hasFileReaderSupport()
      ? await stepImageResize(onError, resizeInput)
      : new Error('Unsupported FileReader in this browser');
  }

  if (isError(resizeOutput)) {
    logWarn(`Proceeding with not resized image: ${stringify(resizeOutput)}`);
  } else {
    logInfo(`file resized: ${bytesToMegabytes(resizeOutput.size)} MB`);
  }

  const prevFile = [resizeOutput, heicOutput].find(isFileOrBlob);
  const compressionInput = prevFile && !isError(prevFile) ? prevFile : file;
  const compressOutput = await stepImageCompression(onError, compressionInput, extraCompressFlag);

  if (isError(compressOutput)) {
    logWarn(`Proceeding with uncompressed image: ${stringify(compressOutput)}`);
  } else {
    logInfo(`file compressed: ${bytesToMegabytes(compressOutput.size)} MB`);
  }

  const extraCompressed =
    !isError(resizeOutput) &&
    !isError(compressOutput) &&
    resizeOutput.size > compressOutput.size &&
    Boolean(extraCompressFlag) &&
    compressOutput.size <= COMPRESS_EXTRA_MAX_SIZE_MB;

  logInfo(`file extra compressed: ${extraCompressed}`);

  const final = [compressOutput, resizeOutput, heicOutput].find(isFileOrBlob);
  if (!final || isError(final)) {
    onError(ImageProcessingStepError.final);
  }
  return final && !isError(final) ? { file: final, extraCompressed } : { error: ImageFileOrUrlProcessingError.other };
};

const processImageUrl = async (
  onError: HandleError,
  url: string,
  extraCompress?: boolean,
): Promise<ProcessedImageFile | ProcessingErrorResult> => {
  let file;
  try {
    file = await imageCompression.getFilefromDataUrl(url, 'imageFileName');
  } catch (error) {
    onError(ImageProcessingStepError.other, error);
  }
  if (!file) {
    logError('Image file is undefined after image compression');
    return { error: ImageFileOrUrlProcessingError.other };
  }
  logInfo(`(getFilefromDataUrl) file from url: ${bytesToMegabytes(file.size)} MB ${file.type}`);

  const output = await runProcessFileScript(onError, undefined, file, extraCompress);
  return output;
};

const useProcessImage = (options?: { allowPdf?: boolean }) => {
  const toast = useToast();
  const imageProcessors = useImgProcessorsContext();
  const { t } = useTranslation('idv', {
    keyPrefix: 'document-flow.components.image-file-url-processing-errors',
  });
  const handleError = (step: ImageProcessingStepError, error?: unknown) => {
    if (error) {
      logError(stringify(error), error);
    }
    toast.show({
      title: 'Uh-oh',
      description: t(step),
    });
  };

  const acceptedFileFormats = ['image/*'];
  if (imageProcessors?.heic) {
    acceptedFileFormats.push('.heic', '.heif');
  }
  if (options?.allowPdf) {
    acceptedFileFormats.push('application/pdf');
  }

  return {
    convertImageFileToStrippedBase64: partial(convertImageFileToStrippedBase64, [handleError]),
    processImageFile: partial(runProcessFileScript, [handleError, imageProcessors]),
    processImageUrl: partial(processImageUrl, [handleError]),
    acceptedFileFormats: acceptedFileFormats.join(','),
  };
};

export default useProcessImage;

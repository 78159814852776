import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type React from 'react';
import Content from './components/content';
import Trigger from './components/trigger';

type TooltipRootProps = {
  children: React.ReactNode;
} & TooltipPrimitive.TooltipProps;

type PortalProps = {
  children: React.ReactNode;
  container?: HTMLElement;
};

const Root = ({ children, disableHoverableContent, ...props }: TooltipRootProps) => {
  return (
    <TooltipPrimitive.Provider delayDuration={0}>
      <TooltipPrimitive.Root open={disableHoverableContent ? false : undefined} {...props}>
        {children}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

const Portal = ({ children, container }: PortalProps) => {
  return <TooltipPrimitive.Portal container={container}>{children}</TooltipPrimitive.Portal>;
};

const Tooltip = {
  Root,
  Trigger,
  Content,
  Portal,
};

export default Tooltip;

import { IcoCheck16 } from '@onefootprint/icons';
import Highlighter from 'react-highlight-words';
import type { GroupBase, OptionProps } from 'react-select';

import { cx } from 'class-variance-authority';
import Flag from '../../../flag';
import type { CountrySelectOption } from '../../country-select.types';

const Option = ({
  innerRef,
  isFocused,
  isSelected,
  innerProps,
  data,
  selectProps,
}: OptionProps<CountrySelectOption, false, GroupBase<CountrySelectOption>>) => {
  const { inputValue } = selectProps;
  const { label, value } = data;

  const handleInteraction = (event: React.KeyboardEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    if (event.type === 'keydown') {
      const keyEvent = event as React.KeyboardEvent;
      if (keyEvent.key === 'Enter' || keyEvent.key === ' ') {
        event.preventDefault();
        innerProps.onClick?.(event as unknown as React.MouseEvent<HTMLDivElement>);
      }
    } else if (event.type === 'focus') {
      const target = event.currentTarget;
      target.dispatchEvent(new MouseEvent('mouseover', { bubbles: true }));
    }
  };

  return (
    <div className="px-2 w-full">
      <div
        ref={innerRef}
        {...innerProps}
        onKeyDown={handleInteraction}
        onFocus={handleInteraction}
        aria-selected={isSelected}
        // biome-ignore lint/a11y/useSemanticElements: TODO: change to <option />
        role="option"
        tabIndex={0}
        aria-label={label}
        className={cx('w-full px-3 py-2 flex items-center justify-between cursor-pointer', {
          'bg-secondary rounded': isFocused,
        })}
      >
        <div className="flex text-primary text-body-3 gap-3 items-center w-full">
          <Flag code={value} className="min-w-[20px]" />
          <Highlighter
            searchWords={[inputValue]}
            textToHighlight={label}
            highlightStyle={{
              background: 'none',
              color: 'var(--color-primary)',
              fontWeight: 600,
            }}
          />
        </div>
        {isSelected && <IcoCheck16 />}
      </div>
    </div>
  );
};

export default Option;

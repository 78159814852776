import { getErrorMessage } from '@onefootprint/request';
import type { UserTokenResponse } from '@onefootprint/types';
import { UserTokenScope } from '@onefootprint/types';

import { runPasskeyChallenge } from '@/idv/components/identify/components/login-challenge/hooks/run-passkey';
import {
  getHostedUserAuthMethodsOptions,
  postHostedIdentifySession,
  postHostedIdentifySessionVerify,
} from '@onefootprint/axios';
import type { GetHostedUserAuthMethodsResponse } from '@onefootprint/request-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import type { DeviceInfo } from '../../hooks';
import { useUserToken } from '../../queries';

type UseStepUpArgs = {
  authToken: string;
  device: DeviceInfo;
  onSuccess?: (authToken: string) => void;
  onError?: (error: unknown) => void;
};

const isStepUpPossible = (
  { type, hasSupportForWebauthn }: DeviceInfo,
  response: GetHostedUserAuthMethodsResponse | undefined,
): boolean => {
  const passkeyAuthMethod = response?.find(method => method.kind === 'passkey' && method.isVerified);
  const clientSide = hasSupportForWebauthn && (type === 'desktop' ? passkeyAuthMethod?.isSyncablePasskey : true);

  return Boolean(passkeyAuthMethod) && Boolean(clientSide);
};

const isStepUpNeeded = (data?: Pick<UserTokenResponse, 'scopes'>) =>
  !data?.scopes.includes(UserTokenScope.sensitiveProfile);

const useStepUp = ({ authToken, device, onSuccess, onError }: UseStepUpArgs) => {
  const userTokenQuery = useUserToken(
    { authToken },
    {
      onError: (error: unknown) => {
        onError?.(`Failed to get user token info for step up. ${getErrorMessage(error)}`);
      },
    },
  );
  const authMethodsQuery = useQuery(getHostedUserAuthMethodsOptions({ headers: { 'X-Fp-Authorization': authToken } }));
  useEffect(() => {
    if (authMethodsQuery.isError) {
      onError?.(`Failed to get user auth methods for step up. ${getErrorMessage(authMethodsQuery.error)}`);
    }
  }, [authMethodsQuery]);
  const runPasskeyMutation = useMutation({
    mutationFn: async () => {
      const {
        data: { token: identifyToken },
      } = await postHostedIdentifySession({
        body: { data: {}, scope: 'onboarding' },
        headers: { 'X-Fp-Authorization': authToken },
        throwOnError: true,
      });
      await runPasskeyChallenge(identifyToken);
      const {
        data: { authToken: steppedUpAuthToken },
      } = await postHostedIdentifySessionVerify({
        headers: { 'X-Fp-Authorization': identifyToken },
        throwOnError: true,
      });
      return steppedUpAuthToken;
    },
  });

  const isLoading = runPasskeyMutation.isPending || userTokenQuery.isLoading || authMethodsQuery.isLoading;
  const needsStepUp = isStepUpNeeded(userTokenQuery.data);
  const canStepUp = isStepUpPossible(device, authMethodsQuery.data);

  const stepUp = async () => {
    if (!needsStepUp) {
      onSuccess?.(authToken);
      return;
    }

    if (!canStepUp) {
      onError?.(
        `Cannot execute step up on current device. ${device.type} device kind, has support for webauthn: ${device.hasSupportForWebauthn}`,
      );
      return;
    }

    if (runPasskeyMutation.isPending) {
      return;
    }

    runPasskeyMutation.mutate(undefined, {
      onSuccess: (authToken: string) => {
        onSuccess?.(authToken);
      },
      onError: (error: unknown) => {
        onError?.(`Encountered error while running passkey challenge: ${getErrorMessage(error)}`);
      },
    });
  };

  return { needsStepUp, canStepUp, stepUp, isLoading };
};

export default useStepUp;
